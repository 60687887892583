import { openDB } from 'idb';

import { Code } from './helpers';

export interface CodeIndexedDB {
  getCodes: () => Promise<{ codes: Code[], version: string } | null>;
  setCodes: (codes: Code[], version: string) => Promise<void>;
  clear: () => Promise<void>;
  close: () => void;
}

/**
 * Creates an IndexedDB handler made to interact with local database. Uses it as a simple key-value store.
 * @param eventId Event ID, required to ensure each event gets their own local database
 * @returns An instance of an IndexedDB handler.
 */
export const makeCodeIndexedDB = async (eventId: string, scannerId?: string): Promise<CodeIndexedDB> => {
  try {
    const name = `codes-${eventId}`;

    const connection = await openDB(name, undefined, {
      upgrade: (db) => {
        db.createObjectStore('codes', {
          keyPath: 'id',
        });
      }
    });

    return {
      getCodes: async () => {
        if (!scannerId) {
          return null;
        }

        try {
          return (await connection.get('codes', scannerId)) || null;
        } catch (error) {
        }
      },
      setCodes: async (codes: Code[], version: string) => {
        if (!scannerId) {
          return;
        }

        try {
          await connection.put('codes', { id: scannerId, version, codes });
          return;
        } catch (error) {
        }
      },
      clear: async () => {
        try {
          return await connection.clear('codes');
        } catch (error) {
        }
      },
      close: () => {
        try {
          return connection.close();
        } catch (error) {
        }
      }
    }
  } catch (error) {
    throw error;
  }
};