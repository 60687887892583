
import { useState, createContext, useCallback, useContext, useEffect, ReactNode, useRef, useMemo } from 'react';

import { ScanItemInput, useScanBatchMutation } from "./global";
import LocalStorage from './LocalStorage';
import { useOfflineStatus } from './OfflineStatusProvider';

export const ScanQueueContext = createContext({} as ScanQueueContextType);

interface ScanQueueContextType {
  queue: ScanItemInput[];
  enqueue: (payload: ScanItemInput) => void;
}

export const useScanQueue = () => useContext(ScanQueueContext);

interface ScanQueueProviderProvider {
  scannerId: string;
  batchSize: number;
  /** How often to try to push the events */
  attemptInterval?: number;
  children?: ReactNode;
}

const ScanQueueProvider = ({ scannerId, children, attemptInterval = 10000, batchSize = 100 }: ScanQueueProviderProvider) => {
  const [scanBatch] = useScanBatchMutation();
  const isDequeuing = useRef<boolean>(false);
  const { isOffline } = useOfflineStatus();

  const storageKey = `scanner:queue:${scannerId}`;

  const initialQueue: ScanItemInput[] = useMemo(() => {
    const queue = LocalStorage.getItem(storageKey);

    if (queue) {
      return JSON.parse(queue);
    }

    return [];
  }, [storageKey])

  /**
   * FIFO queue of ScanItemInput.
   */
  const [queue, setQueue] = useState(initialQueue);

  /**
   * Load existing queue when changing scanner. These could be initially persisted on local-storage,
   * so initial load will attempt to fetch a list of scans from local-storage.
   */
  useEffect(() => {
    setQueue(initialQueue);
  }, [initialQueue]);

  /**
   * Synchronize local-storage with the in-memory queue (for offline persistent storage).
   */
  useEffect(() => {
    LocalStorage.setItem(storageKey, JSON.stringify(queue));
  }, [storageKey, queue]);

  /**
   * Adds a scan to the queue.
   */
  const enqueue = (payload: ScanItemInput) => {
    setQueue((currentQueue) => [...currentQueue, payload]);
  }

  /**
   * Attempts to push the scans to the backend. This can only occur sequentially.
   * If successful, updates the in-memory queue with the remaining items.
   */
  const dequeue = useCallback(async () => {
    if (isDequeuing.current || isOffline) {
      return;
    };

    const items = queue.slice(0, batchSize);

    if (items.length === 0) {
      return;
    }

    isDequeuing.current = true;

    try {
      await scanBatch({
        variables: {
          input: {
            scanner_id: scannerId,
            scans: items.map((item) => ({
              value: item.value,
              revoke: item.revoke,
              scanned_at: item.scanned_at,
              start_number: item.start_number,
            })),
          },
        },
      });

      // We update the queue with items from batchSize onwards
      // (in case we have more items than the batch-size allows)
      setQueue((currentQueue) => currentQueue.slice(items.length, currentQueue.length));
    } catch (error) {
      // Probably something like a network error, try again the next interval.
    }

    isDequeuing.current = false;
  }, [queue, setQueue, scanBatch, batchSize, isOffline, scannerId]);

  /**
   * Whenever the queue or offline status changes, we try to instantly dequeue our items.
   */
  useEffect(() => {
    if (queue.length > 0 && !isOffline) {
      dequeue();
    }
  }, [queue, isOffline, dequeue]);

  /**
   * Every `attemptInterval` seconds, we attempt to cleanup the queue.
   */
  useEffect(() => {
    const timer = window.setInterval(() => {
      dequeue();
    }, attemptInterval);

    return () => window.clearInterval(timer);
  }, [attemptInterval, dequeue]);

  return (
    <ScanQueueContext.Provider value={{ queue, enqueue }}>
      {children}
    </ScanQueueContext.Provider>
  );
};

export default ScanQueueProvider;
