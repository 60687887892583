import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  Date: any;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-05-23 13:43:32`. */
  DateTime: any;
};

export type AddEventInput = {
  project_id: Scalars['ID'];
  title: Scalars['String'];
};

export type AddScannerInput = {
  event_id: Scalars['ID'];
  webhook_url?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  edit_start_numbers?: Maybe<Scalars['Boolean']>;
  configuration?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
};

export type Code = {
  __typename?: 'Code';
  id: Scalars['ID'];
  value: Scalars['String'];
  start_number?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
  scanner: Scanner;
  scans: Array<Scan>;
  scan_count: Scalars['Int'];
  scanned_at?: Maybe<Scalars['DateTime']>;
  updated_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
};

export type CodeInput = {
  value: Scalars['String'];
  start_number?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  response?: Maybe<Scalars['String']>;
};

/** A paginated list of Code items. */
export type CodePaginator = {
  __typename?: 'CodePaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Code items. */
  data: Array<Code>;
};



export type DeleteCodesInput = {
  scanner_id: Scalars['ID'];
  codes?: Maybe<Array<Scalars['String']>>;
};

export type EditEventInput = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type EditScannerInput = {
  id: Scalars['ID'];
  webhook_url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  edit_start_numbers?: Maybe<Scalars['Boolean']>;
  configuration?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
};

export type Event = {
  __typename?: 'Event';
  id: Scalars['ID'];
  title: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
  created_at: Scalars['DateTime'];
  scanners: Array<Scanner>;
  enabled_scanners: Array<Scanner>;
};

/** A paginated list of Event items. */
export type EventPaginator = {
  __typename?: 'EventPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Event items. */
  data: Array<Event>;
};

export type Mutation = {
  __typename?: 'Mutation';
  upsertCodes: Scanner;
  deleteCodes: Scanner;
  addEvent: Event;
  editEvent: Event;
  addProject: Project;
  scanBatch?: Maybe<Scanner>;
  addScanner: Scanner;
  editScanner: Scanner;
  deleteScanner?: Maybe<Scalars['ID']>;
};


export type MutationUpsertCodesArgs = {
  input: UpsertCodesInput;
};


export type MutationDeleteCodesArgs = {
  input: DeleteCodesInput;
};


export type MutationAddEventArgs = {
  input: AddEventInput;
};


export type MutationEditEventArgs = {
  input: EditEventInput;
};


export type MutationScanBatchArgs = {
  input: ScanBatchInput;
};


export type MutationAddScannerArgs = {
  input: AddScannerInput;
};


export type MutationEditScannerArgs = {
  input: EditScannerInput;
};


export type MutationDeleteScannerArgs = {
  id: Scalars['ID'];
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Information about pagination using a Relay style cursor connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** The cursor to continue paginating backwards. */
  startCursor?: Maybe<Scalars['String']>;
  /** The cursor to continue paginating forwards. */
  endCursor?: Maybe<Scalars['String']>;
  /** Total number of nodes in the paginated connection. */
  total: Scalars['Int'];
  /** Number of nodes in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
};

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Number of total available items. */
  total: Scalars['Int'];
};

export type Project = {
  __typename?: 'Project';
  id: Scalars['ID'];
  scanned_at?: Maybe<Scalars['DateTime']>;
  codes: CodePaginator;
  events: EventPaginator;
};


export type ProjectCodesArgs = {
  updated_since?: Maybe<Scalars['DateTime']>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type ProjectEventsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  code: Code;
  event: Event;
  eventByPassword?: Maybe<Event>;
  project: Project;
  scanner?: Maybe<Scanner>;
  me?: Maybe<User>;
};


export type QueryCodeArgs = {
  id: Scalars['ID'];
};


export type QueryEventArgs = {
  id: Scalars['ID'];
  password?: Maybe<Scalars['String']>;
};


export type QueryEventByPasswordArgs = {
  password: Scalars['String'];
};


export type QueryProjectArgs = {
  id: Scalars['ID'];
};


export type QueryScannerArgs = {
  id: Scalars['ID'];
};

export type Scan = {
  __typename?: 'Scan';
  id: Scalars['ID'];
  value: Scalars['String'];
  response?: Maybe<Scalars['String']>;
  status: ScanStatus;
  start_number?: Maybe<Scalars['String']>;
  scanned_at: Scalars['DateTime'];
  created_at: Scalars['DateTime'];
  scanner: Scanner;
  code?: Maybe<Code>;
};

export type ScanBatchInput = {
  scanner_id: Scalars['ID'];
  scans: Array<ScanItemInput>;
};

export type ScanItemInput = {
  value: Scalars['String'];
  scanned_at?: Maybe<Scalars['DateTime']>;
  revoke?: Maybe<Scalars['Boolean']>;
  start_number?: Maybe<Scalars['String']>;
};

export enum ScanStatus {
  /** The scan was successful and the code was valid. */
  Valid = 'VALID',
  /** The scan was successful, but the code was invalid. */
  Invalid = 'INVALID',
  /** The scan failed (i.e. webhook unavailable). */
  Failed = 'FAILED',
  /** The scan was manually revoked (i.e. checked out). */
  Revoked = 'REVOKED',
  /** The code was updated (i.e. start number edited). */
  Updated = 'UPDATED'
}

export type Scanner = {
  __typename?: 'Scanner';
  id: Scalars['ID'];
  title: Scalars['String'];
  webhook_url?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['String']>;
  edit_start_numbers: Scalars['Boolean'];
  enabled: Scalars['Boolean'];
  created_at: Scalars['DateTime'];
  event: Event;
  check_in_count: Scalars['Int'];
  code_count: Scalars['Int'];
  codes: CodePaginator;
};


export type ScannerCodesArgs = {
  search?: Maybe<Scalars['String']>;
  updated_since?: Maybe<Scalars['DateTime']>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};

/** Information about pagination using a simple paginator. */
export type SimplePaginatorInfo = {
  __typename?: 'SimplePaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Number of items per page. */
  perPage: Scalars['Int'];
};

/** The available directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type UpsertCodesInput = {
  scanner_id: Scalars['ID'];
  codes: Array<CodeInput>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  email: Scalars['String'];
  projects: Array<Project>;
};

export type EventSessionFieldsFragment = { __typename?: 'Event', id: string, token: string, title: string, enabled_scanners: Array<{ __typename?: 'Scanner', id: string, title: string, edit_start_numbers: boolean }> };

export type GetCodeScansQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCodeScansQuery = { __typename?: 'Query', code: { __typename?: 'Code', id: string, scans: Array<{ __typename?: 'Scan', id: string, status: ScanStatus, start_number?: Maybe<string>, scanned_at: any }> } };

export type GetEventByPasswordQueryVariables = Exact<{
  password: Scalars['String'];
}>;


export type GetEventByPasswordQuery = { __typename?: 'Query', eventByPassword?: Maybe<{ __typename?: 'Event', id: string, token: string, title: string, enabled_scanners: Array<{ __typename?: 'Scanner', id: string, title: string, edit_start_numbers: boolean }> }> };

export type GetPaginatedCodesQueryVariables = Exact<{
  scanner_id: Scalars['ID'];
  page: Scalars['Int'];
  first: Scalars['Int'];
  updated_since?: Maybe<Scalars['DateTime']>;
}>;


export type GetPaginatedCodesQuery = { __typename?: 'Query', scanner?: Maybe<{ __typename?: 'Scanner', id: string, event: { __typename?: 'Event', id: string, token: string, title: string, enabled_scanners: Array<{ __typename?: 'Scanner', id: string, title: string, edit_start_numbers: boolean }> }, codes: { __typename?: 'CodePaginator', paginatorInfo: { __typename?: 'PaginatorInfo', hasMorePages: boolean }, data: Array<{ __typename?: 'Code', id: string, title?: Maybe<string>, start_number?: Maybe<string>, value: string, response?: Maybe<string>, scanned_at?: Maybe<any>, updated_at: any, deleted_at?: Maybe<any> }> } }> };

export type ScanBatchMutationVariables = Exact<{
  input: ScanBatchInput;
}>;


export type ScanBatchMutation = { __typename?: 'Mutation', scanBatch?: Maybe<{ __typename?: 'Scanner', id: string }> };

export const EventSessionFieldsFragmentDoc = gql`
    fragment EventSessionFields on Event {
  id
  token
  title
  enabled_scanners {
    id
    title
    edit_start_numbers
  }
}
    `;
export const GetCodeScansDocument = gql`
    query GetCodeScans($id: ID!) {
  code(id: $id) {
    id
    scans {
      id
      status
      start_number
      scanned_at
    }
  }
}
    `;

/**
 * __useGetCodeScansQuery__
 *
 * To run a query within a React component, call `useGetCodeScansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCodeScansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCodeScansQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCodeScansQuery(baseOptions: Apollo.QueryHookOptions<GetCodeScansQuery, GetCodeScansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCodeScansQuery, GetCodeScansQueryVariables>(GetCodeScansDocument, options);
      }
export function useGetCodeScansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCodeScansQuery, GetCodeScansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCodeScansQuery, GetCodeScansQueryVariables>(GetCodeScansDocument, options);
        }
export type GetCodeScansQueryHookResult = ReturnType<typeof useGetCodeScansQuery>;
export type GetCodeScansLazyQueryHookResult = ReturnType<typeof useGetCodeScansLazyQuery>;
export type GetCodeScansQueryResult = Apollo.QueryResult<GetCodeScansQuery, GetCodeScansQueryVariables>;
export const GetEventByPasswordDocument = gql`
    query GetEventByPassword($password: String!) {
  eventByPassword(password: $password) {
    ...EventSessionFields
  }
}
    ${EventSessionFieldsFragmentDoc}`;

/**
 * __useGetEventByPasswordQuery__
 *
 * To run a query within a React component, call `useGetEventByPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventByPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventByPasswordQuery({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useGetEventByPasswordQuery(baseOptions: Apollo.QueryHookOptions<GetEventByPasswordQuery, GetEventByPasswordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventByPasswordQuery, GetEventByPasswordQueryVariables>(GetEventByPasswordDocument, options);
      }
export function useGetEventByPasswordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventByPasswordQuery, GetEventByPasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventByPasswordQuery, GetEventByPasswordQueryVariables>(GetEventByPasswordDocument, options);
        }
export type GetEventByPasswordQueryHookResult = ReturnType<typeof useGetEventByPasswordQuery>;
export type GetEventByPasswordLazyQueryHookResult = ReturnType<typeof useGetEventByPasswordLazyQuery>;
export type GetEventByPasswordQueryResult = Apollo.QueryResult<GetEventByPasswordQuery, GetEventByPasswordQueryVariables>;
export const GetPaginatedCodesDocument = gql`
    query GetPaginatedCodes($scanner_id: ID!, $page: Int!, $first: Int!, $updated_since: DateTime) {
  scanner(id: $scanner_id) {
    id
    event {
      ...EventSessionFields
    }
    codes(page: $page, first: $first, updated_since: $updated_since) {
      paginatorInfo {
        hasMorePages
      }
      data {
        id
        title
        start_number
        value
        response
        scanned_at
        updated_at
        deleted_at
      }
    }
  }
}
    ${EventSessionFieldsFragmentDoc}`;

/**
 * __useGetPaginatedCodesQuery__
 *
 * To run a query within a React component, call `useGetPaginatedCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedCodesQuery({
 *   variables: {
 *      scanner_id: // value for 'scanner_id'
 *      page: // value for 'page'
 *      first: // value for 'first'
 *      updated_since: // value for 'updated_since'
 *   },
 * });
 */
export function useGetPaginatedCodesQuery(baseOptions: Apollo.QueryHookOptions<GetPaginatedCodesQuery, GetPaginatedCodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaginatedCodesQuery, GetPaginatedCodesQueryVariables>(GetPaginatedCodesDocument, options);
      }
export function useGetPaginatedCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaginatedCodesQuery, GetPaginatedCodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaginatedCodesQuery, GetPaginatedCodesQueryVariables>(GetPaginatedCodesDocument, options);
        }
export type GetPaginatedCodesQueryHookResult = ReturnType<typeof useGetPaginatedCodesQuery>;
export type GetPaginatedCodesLazyQueryHookResult = ReturnType<typeof useGetPaginatedCodesLazyQuery>;
export type GetPaginatedCodesQueryResult = Apollo.QueryResult<GetPaginatedCodesQuery, GetPaginatedCodesQueryVariables>;
export const ScanBatchDocument = gql`
    mutation ScanBatch($input: ScanBatchInput!) {
  scanBatch(input: $input) {
    id
  }
}
    `;
export type ScanBatchMutationFn = Apollo.MutationFunction<ScanBatchMutation, ScanBatchMutationVariables>;

/**
 * __useScanBatchMutation__
 *
 * To run a mutation, you first call `useScanBatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScanBatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scanBatchMutation, { data, loading, error }] = useScanBatchMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScanBatchMutation(baseOptions?: Apollo.MutationHookOptions<ScanBatchMutation, ScanBatchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScanBatchMutation, ScanBatchMutationVariables>(ScanBatchDocument, options);
      }
export type ScanBatchMutationHookResult = ReturnType<typeof useScanBatchMutation>;
export type ScanBatchMutationResult = Apollo.MutationResult<ScanBatchMutation>;
export type ScanBatchMutationOptions = Apollo.BaseMutationOptions<ScanBatchMutation, ScanBatchMutationVariables>;