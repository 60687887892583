import { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import './NavBar.css';

import { useTranslation } from 'react-i18next';
import { Icon } from './UI';
import { LogOut, Maximize, Menu, Users, X } from 'react-feather';
import { Event, useSession } from './SessionProvider';

interface NavBarProps {
  event: Event;
}

const NavBar = ({ event }: NavBarProps) => {
  const { t } = useTranslation();
  const { logout } = useSession();
  const match = useRouteMatch<{ eventId: string, scannerId?: string; subPath?: string; }>(
    '/:eventId/scanners/:scannerId/:subPath?'
  );
  const eventId = match?.params.eventId;
  const activeScannerId = match?.params.scannerId;
  const activeScanner = event.enabled_scanners.filter(({id}) => id === activeScannerId)[0];
  const subPath = match?.params.subPath;

  const [open, setOpen] = useState(false);

  return (
    <nav className="bg-purple-600 nav-bar">
      <div className="px-4 mx-auto sm:px-0" style={{ maxWidth: 600 }}>
        <div className="relative flex items-center justify-between h-12">
          <div className="min-w-0">
            <div className="text-xs" style={{ lineHeight: 1.2 }}>
              <div className="font-bold text-white truncate">
                {event.title}
              </div>
              {activeScanner && (
                <div className="text-purple-200 truncate">
                  {activeScanner.title}
                </div>
                )}
            </div>
          </div>
          <div className="flex items-center space-x-1 text-xl">
            {activeScanner && (
              <>
                <Link
                  to={`/${event.id}/scanners/${activeScanner.id}/codes`}
                  className={`inline-flex items-center justify-center p-2 rounded-md hover:text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white ml-1 ${subPath === 'codes' ? 'text-white' : 'text-purple-300'}`}
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">{t('codes')}</span>
                  <Icon><Users /></Icon>
                </Link>
                <Link
                  to={`/${event.id}/scanners/${activeScanner.id}/qr-reader`}
                  className={`inline-flex items-center justify-center p-2 rounded-md hover:text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white ${subPath === 'qr-reader' ? 'text-white' : 'text-purple-300'}`}
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">{t('scanner')}</span>
                  <Icon><Maximize /></Icon>
                </Link>
              </>
            )}
            {event.enabled_scanners.length > 0 && (
              <button
                type="button"
                className="inline-flex items-center justify-center p-2 text-purple-300 rounded-md hover:text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-controls="menu"
                aria-expanded={open}
                onClick={() => setOpen((open) => !open)}
              >
                <span className="sr-only">{t('open_main_menu')}</span>
                <Icon>
                  {!open && <Menu />}
                  {open && <X />}
                </Icon>
              </button>
            )}
          </div>
        </div>
      </div>

      {open && (
        <div id="menu" className="px-2 pt-2 pb-3 mx-auto space-y-1 text-center sm:px-0" style={{ maxWidth: 600}}>
          {event.enabled_scanners.map((scanner) => (
            <Link
              to={`/${event.id}/scanners/${scanner.id}${subPath ? `/${subPath}` : ''}`}
              className={
                activeScannerId === scanner.id
                  ? 'bg-purple-900 text-white block px-3 py-2 rounded-md text-base font-medium'
                  : 'text-purple-300 hover:bg-purple-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'
              }
              aria-current={activeScannerId === scanner.id && 'page'}
              onClick={() => setOpen(false)}
              key={scanner.id}
            >
              {scanner.title}
            </Link>
          ))}
          {event.enabled_scanners.length > 0 && <hr className="border-purple-400" />}
          <button
            type="button"
            className="block w-full px-3 py-2 text-base font-medium text-purple-300 rounded-md hover:bg-purple-700 hover:text-white"
            aria-controls="logout"
            onClick={() => logout(eventId as string)}
          >
              <Icon className="mr-2"><LogOut /></Icon>
            {t('logout')}
          </button>
        </div>
      )}
    </nav>
  );
}

export default NavBar;
