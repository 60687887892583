import qs from 'qs';
import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { locales } from './i18n';

import LocalStorage from './LocalStorage';

interface LocaleProviderProps {
  children: ReactNode;
}

const LocaleProvider = ({ children }: LocaleProviderProps) => {
  const history = useHistory();
  const params: { locale?: any; } = qs.parse(history.location.search.substr(1));

  const locale = locales.includes(params.locale)
    ? params.locale
    : LocalStorage.getItem('locale') || locales[0];

  const { i18n } = useTranslation();

  useEffect(() => {
    if (locale) {
      i18n.changeLanguage(locale);
      LocalStorage.setItem('locale', locale);
    }
  }, [locale, i18n]);

  return <>{children}</>;
};

export default LocaleProvider;
