import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { CodeIndexedDB, makeCodeIndexedDB } from './CodeIndexedDB';

import { ScanStatus, useGetPaginatedCodesLazyQuery } from './global';
import { Code } from './helpers';
import { useOfflineStatus } from './OfflineStatusProvider';
import { useScanQueue } from './ScanQueueProvider';
import { useDate } from './useDate';
import { useDebouncedEffect } from './useDebouncedEffect';
import { Event, useSession } from './SessionProvider';

export interface LocalScanInput {
  value: string;
  scanned_at: string;
  scanner_id: string;
  revoke?: boolean;
  start_number?: string;
}

export interface LocalScanResult {
  rescan: boolean;
  scanned_at: string;
  status: ScanStatus;
  code?: Code;
}

export interface CodesProviderProps {
  scannerId: string;
  eventId: string;
  refreshInterval: number;
  codeDb?: CodeIndexedDB;
  children?: ReactNode;
}

export interface CodesContextProps {
  codes: Code[];
  loading: boolean;
  handleScanCode: (input: LocalScanInput) => LocalScanResult;
  getCodeById: (codeId: string) => Code | null;
  getCodeByStartNumber: (startNumber: string) => Code | null;
}

export const CodesContext = createContext({} as CodesContextProps);

const CodesProvider = ({ eventId, scannerId, children, refreshInterval, codeDb }: CodesProviderProps) => {
  const [db, setDb] = useState<CodeIndexedDB | undefined>(codeDb);
  const [version, setVersion] = useState<string | null>(null);
  const [codes, setCodes] = useState<Code[]>([]);
  const [initializing, setInitializing] = useState<boolean>(true);

  const { saveEvent, logout } = useSession();
  const { enqueue } = useScanQueue();
  const { isOffline } = useOfflineStatus();
  const { formatUTC } = useDate();
  const lastFetch = useRef<number | undefined>(undefined);

  /**
   * Initializes the IndexedDB database.
   *
   * The IndexedDB is our persistent storage, used for storing and updating our codes.
   */
  useEffect(() => {
    let mounted = true;

    setInitializing(true);
    setDb(undefined);
    setVersion(null);
    setCodes([]);

    lastFetch.current = undefined;

    makeCodeIndexedDB(eventId, scannerId).then(indexedDb => {
      if (mounted) {
        setDb(indexedDb);
      }
    });

    return () => {
      mounted = false;
    }
  }, [eventId, scannerId]);

  /**
   * Start the scanner by loading the codes from offline storage (if any).
   */
   useEffect(() => {
    let mounted = true;
    if (db && initializing) {
      db.getCodes().then((result) => {
        if (mounted) {
        setCodes(result?.codes || []);
        setVersion(result?.version || null);
        setInitializing(false);
        }
      });
    }

    return () => {
      mounted = false;
    }
  }, [db, initializing]);

  /**
   * Keep track of the codes that have been written to IndexedDB.
   */
  const codesRef = useRef<Code[]>([]);

  /**
   * When changed, write in-memory codes to offline persistent storage.
   *
   * Because IndexedDB is async, we need to wait for it to initialize first before we can use it.
   * Therefore, this has to be placed in its own `useEffect`.
   *
   * A small delay of 50ms is added, because otherwise React seems to wait for the db.setCodes call
   * to complete. With the delay, the check icon immediately becomes green when clicking 'Check in'.
   */
  useDebouncedEffect(() => {
    if (db && !initializing && version && codesRef.current !== codes) {
      codesRef.current = codes;
      db.setCodes(codes, version);
    }
  }, 50, [db, initializing, codes, version]);

  const codeIndexById: Record<string, number> = useMemo(() => {
    return codes.reduce((acc, code, index) => {
      if (!acc[code.id]) {
        acc[code.id] = index;
      }

      return acc;
    }, {} as Record<string, number>)
  }, [codes]);

  const codeIndexByValue: Record<string, number> = useMemo(() => {
    return codes.reduce((acc, code, index) => {
      if (!acc[code.value]) {
        acc[code.value] = index;
      }

      return acc;
    }, {} as Record<string, number>)
  }, [codes]);

  const codeIndexByStartNumber: Record<string, number> = useMemo(() => {
    return codes.reduce((acc, code, index) => {
      if (code.start_number && !acc[code.start_number]) {
        acc[code.start_number] = index;
      }

      return acc;
    }, {} as Record<string, number>)
  }, [codes]);

  /**
   * Validates the scan locally, and derives the result of the scan (revoked or not).
   */
  const validateScan = (input: LocalScanInput, code: Code): LocalScanResult => {
    const status = input.revoke ? ScanStatus.Revoked : (input.start_number ? ScanStatus.Updated : ScanStatus.Valid);

    return {
      status,
      scanned_at: input.scanned_at,
      rescan: status === ScanStatus.Valid ? !!code.scanned_at : false,
    }
  };

  const getCheckedOutSortValue = useCallback((code: Code) => (code.title || '').toLowerCase(), []);
  const getCheckedInSortValue = useCallback((code: Code) => code.scanned_at, []);

  /**
   * Sort the codes as follows:
   * 1) The codes that are checked out, sorted by title
   * 2) The codes that are checked in, sorted by check-in time (latest first)
   *
   * The comparisons should be fast, being able to sort 50.000 codes in max ~50ms.
   */
  const sortCodes = useCallback((codes: Code[]) => ([
    ...codes.filter((code) => !code.scanned_at).sort((a: Code, b: Code) => {
      const valueA = getCheckedOutSortValue(a);
      const valueB = getCheckedOutSortValue(b);

      return valueA === valueB ? 0 : (valueA < valueB ? -1 : 1);
    }),
    ...codes.filter((code) => !!code.scanned_at).sort((a: Code, b: Code) => {
      const valueA = getCheckedInSortValue(a);
      const valueB = getCheckedInSortValue(b);

      return valueA === valueB ? 0 : (valueA > valueB ? -1 : 1);
    }),
  ]), [getCheckedOutSortValue, getCheckedInSortValue]);

  /**
   * Given an array of codes that is sorted using sortCodes(), insert one element
   * without re-sorting the rest of the element.
   */
  const insertCode = (codes: Code[], code: Code) => {
    const checkedOut = codes.filter((code) => !code.scanned_at);
    const checkedIn = codes.filter((code) => !!code.scanned_at);

    if (!code.scanned_at) {
      const value = getCheckedOutSortValue(code);

      return [
        ...checkedOut.filter((element) => getCheckedOutSortValue(element) < value),
        code,
        ...checkedOut.filter((element) => getCheckedOutSortValue(element) >= value),
        ...checkedIn,
      ];
    } else {
      const value = getCheckedInSortValue(code);

      return [
        ...checkedOut,
        ...checkedIn.filter((element) => getCheckedInSortValue(element) > value),
        code,
        ...checkedIn.filter((element) => getCheckedInSortValue(element) <= value),
      ];
    }
  };

  /**
   * Scan a single code, and update our stores (internal store + persistent store + upload queue store).
   *
   * - This will update our state memory `codes`, which will directly synchronize with our offline storage.
   * - This will also queue the scan for sending online.
   */
  const handleScanCode = (input: LocalScanInput): LocalScanResult => {
    const updatedCodes = [...codes];

    // Get the code from local database by value or by start number
    // Using typeof operator because the index can be 0
    const codeIndex = typeof codeIndexByValue[input.value] !== 'undefined'
      ? codeIndexByValue[input.value] : codeIndexByStartNumber[input.value];

    const code = updatedCodes[codeIndex];

    if (code) {
      // Update the scanned value to the code's value when the code was scanned by start number.
      // This way, the server only has to lookup codes by its value, and not by start number.
      input.value = code.value;
    }

    // Queue the scan input to upload to the backend
    enqueue(input);

    // If the code index does not exist
    if (!code) {
      return {
        status: ScanStatus.Invalid,
        scanned_at: input.scanned_at,
        rescan: false,
      };
    }

    // Validate the scan prior to the codes being updated
    const scanResult = validateScan(input, code);

    // Update local database of codes
    const updatedCode: Code = {
      ...code,
      start_number: scanResult.status === ScanStatus.Updated && input.start_number ? input.start_number : code.start_number,
      scanned_at: scanResult.status === ScanStatus.Revoked ? null : (scanResult.status === ScanStatus.Valid ? input.scanned_at : code.scanned_at),
    }

    // Remove the code from the sorted array of codes and insert efficiently at the right place.
    delete updatedCodes[codeIndex];
    setCodes(insertCode(updatedCodes, updatedCode));

    // Validate the scan directly
    return {
      ...scanResult,
      code: updatedCode,
    };
  }

  /**
   * Gets a code from the codes, or returns null.
   */
  const getCodeById = (codeId: string): Code | null => {
    return codes[codeIndexById[codeId]] || null;
  }

  const getCodeByStartNumber = (startNumber: string): Code | null => {
    return codes[codeIndexByStartNumber[startNumber]] || null;
  }

  const [query] = useGetPaginatedCodesLazyQuery({
    fetchPolicy: 'no-cache',
  });

  interface FetchCodesResult {
    event?: Event;
    codes: Code[];
  }

  /**
   * Recursively fetch data from the backend.
   *
   * Returns undefined when an error occurred.
   */
  const handleConsecutiveFetch = useCallback(async (
    page: number = 1,
  ): Promise<FetchCodesResult> => {
    const { data } = await query({
      variables: {
        first: 10000,
        page,
        scanner_id: scannerId,
        updated_since: version,
      },
    });

    if (data === undefined) {
      // An error occurred while fetching the data (e.g. no internet).
      throw Error();
    }

    if (!data.scanner) {
      // Token expired
      logout(eventId);

      return { codes: [] };
    }

    const currentPage = data.scanner.codes.data;
    const nextPage = data.scanner.codes.paginatorInfo.hasMorePages
      ? await handleConsecutiveFetch(page + 1)
      : { codes: [] };

    return {
      event: data.scanner.event,
      codes: [
        ...currentPage,
        ...nextPage.codes,
      ],
    };
  }, [eventId, scannerId, version, query, logout]);

  const fetchCodes = useCallback(async () => {
    // Retrieve updates since the last fetch.
    const result = await handleConsecutiveFetch();

    if (result.codes.length === 0) {
      // When there are no updates, skip rebuilding and sorting the list of codes,
      // because sorting and writing the codes to IndexedDB are cpu intensive operations.
      return { ...result, codes };
    }

    // Map the codes by ID, for easy inserting, updating and deleting codes.
    const codeMap = codes.reduce((codes, code) => {
      codes[code.id] = code;

      return codes;
    }, {} as { [id: string]: Code });

    // Apply patches
    result.codes.forEach((code) => {
      if (code.deleted_at) {
        delete codeMap[code.id];
      } else {
        codeMap[code.id] = code;
      }
    });

    // Sort codes by title
    return {
      ...result,
      codes: sortCodes(Object.values(codeMap)),
    };
  }, [codes, handleConsecutiveFetch, sortCodes]);

  /**
   * After loading the codes from offline storage, check for updates every `refreshInterval` seconds.
   */
  useEffect(() => {
    if (!initializing) {
      let cleared: boolean = false;
      let timer: number;

      const refetch = () => {
        // When loading the scanner, the first fetch after loading the codes should be instant.
        // Otherwise, fetch the codes every refreshInterval milliseconds.
        // Because the useEffect is cleaned up when the codes change (i.e. upon scanning), the timestamp
        // of the most recent fetch is used to determine the timeout.
        const timeout = !lastFetch.current ? 0 : Math.max(refreshInterval - (Date.now() - lastFetch.current), 0);

        timer = window.setTimeout(() => {
          lastFetch.current = Date.now();
          const now = new Date();

          fetchCodes()
            .then((result) => {
              // Only continue if the useEffect hasn't been cleaned up while waiting for the Promise to resolve.
              if (!cleared) {
                setCodes(result.codes);
                setVersion(formatUTC(now));

                if (result.event) {
                  saveEvent(result.event); // Update token
                }
              }
            })
            .catch(() => {
              if (!cleared) {
                refetch();
              }
            });
        }, timeout);
      };

      refetch();

      return () => {
        cleared = true;
        clearTimeout(timer);
      };
    }
  }, [fetchCodes, version, initializing, refreshInterval, formatUTC, saveEvent]);

  /**
   * We are loading when the codes haven't been loaded from offline storage yet,
   * or when we are online and the updates haven't been fetched at least once yet.
   */
  const loading = initializing || (!isOffline && version === null);

  return (
    <CodesContext.Provider value={{ codes, getCodeById, getCodeByStartNumber, loading, handleScanCode }}>
      {children}
    </CodesContext.Provider>
  );
}

export const useCodes = () => useContext(CodesContext);

export default CodesProvider;
