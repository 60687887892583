import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

export interface OfflineStatusProviderProps {
  offline?: boolean;
  children?: ReactNode;
}

export interface OfflineStatusContextProps {
  isOffline: boolean;
}

export const OfflineStatusContext = createContext({} as OfflineStatusContextProps);

const getOfflineStatus = () =>
  typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
    ? !navigator.onLine
    : false;

const OfflineStatusProvider = ({ children, offline = undefined }: OfflineStatusProviderProps) => {
  const [isOffline, setIsOffline] = useState(offline !== undefined ? offline : getOfflineStatus());

  const setOnline = () => setIsOffline(false);
  const setOffline = () => setIsOffline(true);

  useEffect(() => {
    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);

    return () => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, []);

  return (
    <OfflineStatusContext.Provider value={{ isOffline }}>
      {children}
    </OfflineStatusContext.Provider>
  );
}

export const useOfflineStatus = () => useContext(OfflineStatusContext);

export default OfflineStatusProvider;
