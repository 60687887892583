import { useCallback } from "react";

export enum DateFormat {
  Default = 'yyyy-MM-dd HH:mm:ss',
}

export const useDate = () => ({
  formatUTC: useCallback((date: Date, format: DateFormat = DateFormat.Default): string => {
    if (format === DateFormat.Default) {
      const parts = date.toISOString().split("T"); // Convert to UTC, e.g.: 2022-12-09T15:00:20.173Z

      const datePart = parts[0];
      const timePart = parts[1].split(".")[0];

      return datePart + ' ' + timePart;
    }

    return date.toLocaleDateString();
  }, []),
});