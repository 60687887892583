import { MockedResponse } from '@apollo/client/testing';
import { GraphQLError } from 'graphql';

import { GetPaginatedCodesQuery } from './global';

import format from 'date-fns/format';

export type Code = NonNullable<GetPaginatedCodesQuery['scanner']>['codes']['data'][0];

export function inProductionEnvironment(): boolean {
  return window.location.href.startsWith('https://eventscanner.atleta.cc');
};

export function mock<R=any, V=any>(
  query: any, variables: V, response?: { data: R; } | { errors: GraphQLError[] }
): MockedResponse {
  return {
    request: {
      query,
      variables,
    },
    newData: jest.fn(() => response || ({} as any)),
  };
}

/**
 * Returns an object with two keys:
 * - checkedIn: indicates whether the last scan was successful.
 * - valid: the code is valid is it's checked in and it was not a duplicate scan.
 */
export const validateCode = (
  code: {
    scanned_at?: string | null;
  },
  rescan: boolean
) => {
  const checkedIn = !!code?.scanned_at;
  const valid = checkedIn && !rescan;
  return { checkedIn, valid };
};

export const formatNumber = (value: number) => {
  return value.toLocaleString();
}

export const formatTimestamp = (timestamp: string) => {
  const date = new Date(`${timestamp.split(' ').join('T')}Z`);

  return format(date, 'yyyy-MM-dd, HH:mm:ss');
}
