import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { ScanStatus } from './global';

export const locales = [
  'en', 'nl',
];

const translations = {
  general: {
    n_scans_queued_one: {
      en: '{{ scans }} scan offline',
      nl: '{{ scans }} scan offline',
    },
    n_scans_queued_other: {
      en: '{{ scans }} scans offline',
      nl: '{{ scans }} scans offline',
    },
    online: {
      en: 'Online',
      nl: 'Online',
    },
    offline: {
      en: 'Offline',
      nl: 'Offline',
    },
    login: {
      en: 'Log in',
      nl: 'Inloggen',
    },
    logout: {
      en: 'Log out',
      nl: 'Uitloggen',
    },
    enter_password_to_continue: {
      en: 'Enter password to continue',
      nl: 'Vul het wachtwoord in om te beginnen',
    },
    select_scanner_to_continue: {
      en: 'Select a scanner to continue',
      nl: 'Selecteer een scanner om te beginnen',
    },
    no_scanners_yet: {
      en: 'No scanners yet',
      nl: 'Er zijn nog geen scanners',
    },
    start_scanning: {
      en: 'Start scanning',
      nl: 'Start met scannen',
    },
    scan_a_ticket: {
      en: 'Scan a ticket',
      nl: 'Scan een ticket',
    },
    loading: {
      en: 'Loading',
      nl: 'Laden',
    },
    loading_scanner: {
      en: 'Loading {{ name }}...',
      nl: '{{ name }} laden...',
    },
    could_not_load_camera: {
      en: 'Could not load camera',
      nl: 'Kon camera niet laden',
    },
    invalid_code: {
      en: 'Invalid code',
      nl: 'Ongeldige code',
    },
    invalid_password: {
      en: 'Invalid password',
      nl: 'Ongeldig wachtwoord',
    },
    clear: {
      en: 'Clear',
      nl: 'Wissen',
    },
    scan_count: {
      en: 'Scanned {{ count }}x',
      nl: '{{ count }}x gescand',
    },
    something_went_wrong: {
      en: 'Something went wrong',
      nl: 'Er ging iets mis',
    },
    event_not_found: {
      en: 'Event not found',
      nl: 'Evenement niet gevonden',
    },
    open_main_menu: {
      en: 'Open main menu',
      nl: 'Hoofdmenu openen',
    },
    back_to_overview: {
      en: 'Back to the overview',
      nl: 'Terug naar het overzicht',
    },
    not_scanned_yet: {
      en: 'This code has not been scanned yet.',
      nl: 'Deze code is nog niet gescand.',
    },
    show_more: {
      en: 'Show more',
      nl: 'Meer weergeven',
    },
    check_in: {
      en: 'Check in',
      nl: 'Inchecken',
    },
    check_out: {
      en: 'Check out',
      nl: 'Uitchecken',
    },
    all: {
      en: 'All',
      nl: 'Alle',
    },
    checked_in: {
      en: 'Checked in',
      nl: 'Ingecheckt',
    },
    already_checked_in: {
      en: 'Already checked in',
      nl: 'Al eerder ingecheckt',
    },
    checked_out: {
      en: 'Checked out',
      nl: 'Uitgecheckt',
    },
    not_checked_in: {
      en: 'Not checked in',
      nl: 'Niet ingecheckt',
    },
    n_tickets_one: {
      en: '{{ count }} ticket',
      nl: '{{ count }} ticket',
    },
    n_tickets_other: {
      en: '{{ count }} tickets',
      nl: '{{ count }} tickets',
    },
    invalid: {
      en: 'Invalid',
      nl: 'Ongeldig',
    },
    scanner: {
      en: 'Scanner',
      nl: 'Scanner',
    },
    codes: {
      en: 'Codes',
      nl: 'Codes',
    },
    no_codes_found: {
      en: 'No codes found',
      nl: 'Geen codes gevonden',
    },
    search: {
      en: 'Search',
      nl: 'Search',
    },
    scan_or_enter_start_number: {
      en: 'Scan or enter a bib number',
      nl: 'Scan of voer een startnummer in',
    },
    enter_start_number: {
      en: 'Enter a bib number',
      nl: 'Voer een startnummer in',
    },
    assign_start_number: {
      en: 'Assign bib number',
      nl: 'Startnummer toewijzen',
    },
    edit_start_number: {
      en: 'Edit bib number',
      nl: 'Startnummer wijzigen',
    },
    no_start_number_assigned_yet: {
      en: 'No bib number assigned yet',
      nl: 'Nog geen startnummer toegewezen'
    },
    start_number_value: {
      en: 'Bib number: <0>{{ number }}</0>',
      nl: 'Startnummer: <0>{{ number }}</0>',
    },
    start_number_invalid_description: {
      en: 'Please enter a number. For example: 8293',
      nl: 'Voer een getal in. Bijvoorbeeld: 8293',
    },
    start_number_not_unique_description: {
      en: 'This bib number is already assigned to someone else.',
      nl: 'Dit startnummer is al toegewezen aan een ander.',
    },
    save: {
      en: 'Save',
      nl: 'Opslaan',
    },
    cancel: {
      en: 'Cancel',
      nl: 'Annuleren',
    },
  },
  scan_status: {
    [ScanStatus.Valid]: {
      en: 'Valid',
      nl: 'Geldig',
    },
    [ScanStatus.Invalid]: {
      en: 'Invalid',
      nl: 'Ongeldig',
    },
    [ScanStatus.Failed]: {
      en: 'Failed',
      nl: 'Mislukt',
    },
    [ScanStatus.Revoked]: {
      en: 'Revoked',
      nl: 'Ingetrokken',
    },
    [ScanStatus.Updated]: {
      en: 'Bib number edited',
      nl: 'Startnummer gewijzigd',
    },
  }
};

const parseLanguageLines = (language: string, input: any, output?: any) => {
  output = output || {};

  Object.keys(input).forEach((key) => {
    const value = input[key];

    const leaf = typeof value[Object.keys(value)[0]] === 'string';

    if (leaf) {
      if (language in value) {
        output[key] = value[language];
      }
    } else {
      output[key] = {};

      parseLanguageLines(language, value, output[key]);
    }
  });

  return output;
};

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: parseLanguageLines('en', translations),
      nl: parseLanguageLines('nl', translations),
    },
    lng: 'en',
    interpolation: {
      escapeValue: false,
    },
    defaultNS: 'general',
  });

  export default i18n;
