import useSound from 'use-sound';
import { LocalScanResult } from './CodesProvider';

import { ScanStatus } from './global';

const useScanSound = () => {
  const [playSuccess] = useSound('/success.mp3');
  const [playRescan] = useSound('/rescan.mp3');
  const [playError] = useErrorSound();

  return (scan: LocalScanResult) => {
    if (scan.status === ScanStatus.Valid) {
      /** If the second last scan was also valid, it's considered a rescan */
      if (!scan.rescan) {
        playSuccess();
      } else {
        playRescan();
      }
    } else if (scan.status === ScanStatus.Updated) {
      playSuccess();
    } else {
      playError();
    }

    navigator?.vibrate?.(50);
  };
};

export const useErrorSound = () => {
  return useSound('/error.mp3');
};

export default useScanSound;
