import { useContext, useEffect, useRef } from 'react';
import { ChevronLeft } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';
import { Link, Redirect, useHistory } from 'react-router-dom';

import CheckInButton from './CheckInButton';
import { LocalScanInput, useCodes } from './CodesProvider';
import EditStartNumberForm from './EditStartNumberForm';
import { EventContext } from './EventProvider';
import { GetCodeScansDocument, ScanStatus, useGetCodeScansQuery } from './global';
import { formatTimestamp, validateCode } from './helpers';
import { useOfflineStatus } from './OfflineStatusProvider';
import ScanStatusIcon from './ScanStatusIcon';
import { Container, Icon, LinkButton, PageLoader } from './UI';

export interface CodePageProps {
  scannerId: string;
  codeId: string;
  action?: string;
}

const CodePage = ({ scannerId, codeId, action }: CodePageProps) => {
  const { t } = useTranslation();
  const { isOffline } = useOfflineStatus();
  const history = useHistory();
  const event = useContext(EventContext);

  const { getCodeById: getCode, loading: loadingCodes, handleScanCode } = useCodes();

  const editingStartNumber = action === 'edit_start_number';

  const { data, client, refetch: refetchScans, called } = useGetCodeScansQuery({
    skip: isOffline,
    variables: {
      id: codeId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const isOfflineRef = useRef(isOffline);

  /**
   * If we are online again, refresh the scans.
   */
  useEffect(() => {
    if (called && isOfflineRef.current !== isOffline) {
      // We only can refetch after the first fetch
      if (!isOffline) {
        refetchScans();
      }

      isOfflineRef.current = isOffline;
    }
  }, [isOffline, called, refetchScans]);

  /**
   * Given a check in with a scan (possibly revokable), we update our internal stores using `handleScanCode`. We
   * also update our Apollo cache to update the scans.
   */
  const handleScan = (scan: LocalScanInput) => {
    const result = handleScanCode(scan);

    if (data?.code) {
      // Update the cache so we instantly show the scans, even when the scans are not uploaded yet.
      client.cache.writeQuery({
        query: GetCodeScansDocument,
        data: {
          code: {
            ...data?.code,
            scans: [
              {
                id: new Date().getTime(),
                scanned_at: result.scanned_at,
                status: result.status,
                start_number: scan.start_number || null,
                __typename: 'Scan',
              },
              ...data?.code.scans || []
            ]
          }
        },
        variables: {
          id: codeId,
        }
      });
    }

    return result;
  };

  const loadedScans = data !== undefined;
  const scans = data?.code?.scans || [];

  if (loadingCodes) {
    return <PageLoader />;
  }

  const scanner = event.enabled_scanners.filter(({ id }) => id === scannerId)[0];
  const code = getCode(codeId);

  const { checkedIn, valid } = code ? validateCode(code, false) : { checkedIn: false, valid: false};

  if (!code) {
    return <Redirect to={`/${event.id}/scanners/${scannerId}`} />;
  }

  return (
    <Container>
      <div className="p-4 sm:px-0">
        <Link to={`/${event.id}/scanners/${scannerId}/codes`} className="text-sm">
          <Icon>
            <ChevronLeft />
          </Icon>
          {' '}
          {t('back_to_overview')}
        </Link>
      </div>

      <hr />

      <div className="flex items-center p-4 sm:px-0" style={{ lineHeight: 1.1 }}>
        <div className="mr-2">
          <ScanStatusIcon checkedIn={checkedIn} valid={valid} />
        </div>
        <div>
          <strong>
            {code.title}
          </strong>
          <div className="space-x-2">
            <small className="text-gray-500">
              {checkedIn ? t('checked_in') : t('not_checked_in')}
            </small>
            <code className="text-xs text-gray-400">
              {code.value}
            </code>
          </div>
        </div>
      </div>

      <hr />

      {editingStartNumber && (
        <EditStartNumberForm
          code={code}
          scanner={scanner}
          inputLabel={t('enter_start_number')}
          onCancel={() => history.push(`/${event.id}/scanners/${scannerId}/codes/${code.id}`)}
          onSave={handleScan}
        />
      )}
      {!editingStartNumber && (
        <>
          {(code.start_number || code.response) && (
            <>
              <div className="p-4 sm:px-0">
                {code.start_number && (
                  <>
                    <Trans i18nKey="start_number_value" values={{ number: code.start_number }}>
                      <span className="inline-block px-1 py-0.25 text-sm text-gray-800 bg-gray-200 leading-sm font-bold" />
                    </Trans>
                    {code.response && (
                      <>
                        <br /><br />
                      </>
                    )}
                  </>
                )}

                {code.response && (
                  <div dangerouslySetInnerHTML={{ __html: code.response }} />
                )}
              </div>
              <hr />
           </>
          )}

          <div className="p-4 space-y-4 sm:px-0">
            <CheckInButton code={code} scannerId={scanner.id} onToggle={handleScan} />

            {scanner.edit_start_numbers && (
              <LinkButton to={`/${event.id}/scanners/${scannerId}/codes/${code.id}/edit_start_number`} background="gray">
                {code.start_number ? t('edit_start_number') : t('assign_start_number')}
              </LinkButton>
            )}
          </div>

          <hr />

          <div className="p-4 space-y-4 text-center sm:px-0">
            {scans.length > 0 && (
              <div className="text-sm">
                <strong>
                  {t('scan_count', { count: scans.length })}
                </strong>

                {scans.map((scan) => (
                  <div
                    className={scan.status === ScanStatus.Revoked ? 'text-gray-400' : 'text-gray-500'}
                    key={scan.id}
                  >
                    {formatTimestamp(scan.scanned_at)}
                    {' • '}
                    {t(`scan_status:${scan.status}`)}
                    {scan.status === ScanStatus.Updated && `: ${scan.start_number}`}
                  </div>
                ))}
              </div>
            )}
            {scans.length === 0 && (
              <>
                {code.scanned_at && (
                  <div
                    className="text-gray-500"
                  >
                    {code.scanned_at}
                    {' • '}
                    {t(`scan_status:VALID`)}
                  </div>
                )}
                {!code.scanned_at && loadedScans && (
                  <div className="text-gray-400">
                    {t('not_scanned_yet')}
                  </div>
                )}
              </>
            )}
          </div>
        </>
      )}
    </Container>
  );
}

export default CodePage;
