import { ChangeEvent, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Form, FormGrid, Input } from './UI';
import { Code } from './helpers';
import { useDate } from './useDate';
import { LocalScanInput, useCodes } from './CodesProvider';
import { useErrorSound } from './useScanSound';

export interface EditStartNumberFormProps {
  code: Code;
  scanner: {
    id: string;
  };
  startNumberScan?: string | null;
  inputLabel: ReactNode;
  onCancel: () => void;
  onSave: (scan: LocalScanInput) => void;
}

const EditStartNumberForm = ({ code, scanner, startNumberScan, inputLabel, onCancel, onSave }: EditStartNumberFormProps) => {
  const { t } = useTranslation();
  const { formatUTC } = useDate();
  const { getCodeByStartNumber } = useCodes();
  const [playErrorSound] = useErrorSound();

  const [startNumber, setStartNumber] = useState('');

  // Only allow integers
  const isNumber = (startNumber: string) => /^[1-9]\d*$/.test(startNumber);
  const isUnique = useCallback((startNumber: string) => (
    !getCodeByStartNumber(startNumber) || code.start_number === startNumber
  ), [code.start_number, getCodeByStartNumber]);
  const validate = useCallback((startNumber: string) => isNumber(startNumber) && isUnique(startNumber), [isUnique]);
  const valid = validate(startNumber);

  const submit = useCallback((startNumber: string) => {
    const input: LocalScanInput = {
      scanner_id: scanner.id,
      value: code.value,
      scanned_at: formatUTC(new Date(Date.now())), // Use Date.now to be able to mock the time in tests.
      start_number: startNumber,
    };

    onSave(input);

    onCancel();
  }, [scanner.id, code.value, formatUTC, onCancel, onSave]);

  const startNumberRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (startNumberScan) {
      if (validate(startNumberScan)) {
        submit(startNumberScan);
      } else {
        setStartNumber(startNumberScan);

        if (startNumberRef.current) {
          if (startNumberRef.current.value !== startNumberScan) {
            playErrorSound();
          }

          startNumberRef.current.value = startNumberScan;
        }
      }
    }
  }, [startNumberScan, submit, playErrorSound, validate]);

  const handleNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStartNumber(event.target.value);
  };

  return (
    <>
      <Form onSubmit={() => submit(startNumber)}>
        <FormGrid className="p-4 sm:px-0">
          <div>
            <label htmlFor="number" className="font-bold">
              {inputLabel}
            </label>
          </div>
          <Input
            type="text"
            id="number"
            onChange={handleNumberChange}
            maxLength={9}
            placeholder={code.start_number || ''}
            min={0}
            ref={startNumberRef}
            inputMode="numeric"
            className={`${startNumber && !valid && 'border-red-500 focus:ring-red-500 focus:border-red-500'}`}
          />

          {startNumber && !isNumber(startNumber) && (
            <div className="text-red-700">
              {t('start_number_invalid_description')}
            </div>
          )}

          {startNumber && !isUnique(startNumber) && (
            <div className="text-red-700">
              {t('start_number_not_unique_description')}
            </div>
          )}

          <Button
            type="submit"
            disabled={!valid}
            className={!valid ? 'opacity-50' : ''}
          >
            {t('save')}
          </Button>

          <Button
            onClick={onCancel}
            background={'gray'}
          >
            {t('cancel')}
          </Button>
        </FormGrid>
      </Form>
    </>
  )
};

export default EditStartNumberForm;
