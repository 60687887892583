import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { Search, XCircle } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useCodes } from './CodesProvider';

import QueryString from 'qs';
import { EventContext } from './EventProvider';
import { Code, formatNumber, validateCode } from './helpers';
import ScanStatusIcon from './ScanStatusIcon';
import { Container, Icon, Input, LinkButton, PageLoader } from './UI';
import useClientPaginate from './useClientPaginate';

export interface CodesPageProps {
  scannerId: string;
  perPage?: number;
}

const CodesPage = ({ scannerId, perPage = 50 }: CodesPageProps) => {
  const { t } = useTranslation();
  const event = useContext(EventContext);
  const history = useHistory();
  const { codes: allCodes, loading } = useCodes();

  /**
   * Calculate the statistics for all "check-ins"
   */
  const { checkInCount, totalCount } = useMemo(() => (
    allCodes.reduce((acc, code) => {
      acc.checkInCount += !!code.scanned_at ? 1 : 0
      acc.totalCount += 1;

      return acc;
    }, { checkInCount: 0, totalCount: 0 } as { checkInCount: number, totalCount: number })
  ), [allCodes]);

  const checkedInFilter = useCallback((code: Code) => !!code?.scanned_at, []);
  const checkedOutFilter = useCallback((code: Code) => !code?.scanned_at, []);

  const params: {
    filter?: string | null;
    search?: string | null;
  } = QueryString.parse(history.location.search.substr(1));

  const filter = {
    checked_in: checkedInFilter,
    not_checked_in: checkedOutFilter,
  }[params.filter || ''];

  /**
   * Paginate the codes on the client-side, and allow search for 'title'.
   */
  const { paginatedItems: codes, search, handleSearch, isAtEnd, nextPageLink } = useClientPaginate(allCodes, {
    perPage,
    filter,
    searchAttributes: ['start_number', 'title', 'value']
  });

  const searchTimerRef = useRef<number>();

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    window.clearTimeout(searchTimerRef.current);

    searchTimerRef.current = window.setTimeout(() => {
      handleSearch(event.target.value)
    }, 300);
  };

  const searchRef = useRef<HTMLInputElement | null>(null);

  const resetSearch = () => {
    window.clearTimeout(searchTimerRef.current);

    if (searchRef.current) {
      searchRef.current.value = '';
    }

    handleSearch('');
  };

  useEffect(() => {
    return () => window.clearTimeout(searchTimerRef.current);
  }, []);

  if (event.enabled_scanners.filter((scanner) => scanner.id === scannerId).length === 0) {
    return <Redirect to={`/${event.id}`} />;
  }

  if (loading) {
    return <PageLoader />;
  }

  return (
    <div >
      <Container>
        <div className="p-4 sm:px-0">
          <div className="flex mb-2 space-x-1">
            <Link
              to={`?${QueryString.stringify({ ...params, filter: null, limit: null }, { skipNulls: true })}`}
              className={`p-2 leading-tight border text-gray-800 hover:bg-gray-200 text-sm rounded-md ${!filter ? 'bg-gray-200' : 'bg-transparent'}`}
              style={{ flex: 0 }}
            >
              <span className="font-medium whitespace-nowrap">
                {t('all')}
              </span>
              {' '}
              <span className="text-xs text-gray-500">
                {formatNumber(totalCount)}
              </span>
            </Link>
            <Link
              to={`?${QueryString.stringify({ ...params, filter: 'not_checked_in', limit: null }, { skipNulls: true })}`}
              className={`p-2 leading-tight border text-gray-800 hover:bg-gray-200 text-sm rounded-md ${filter === checkedOutFilter ? 'bg-gray-200' : 'bg-transparent'}`}
              style={{ flex: 0 }}
            >
              <span className="font-medium whitespace-nowrap">
                {t('not_checked_in')}
              </span>
              {' '}
              <span className="text-xs text-gray-500">
                {formatNumber(totalCount - checkInCount)}
              </span>
              </Link>
            <Link
              to={`?${QueryString.stringify({ ...params, filter: 'checked_in', limit: null }, { skipNulls: true })}`}
              className={`p-2 leading-tight border text-gray-800 hover:bg-gray-200 text-sm rounded-md ${filter === checkedInFilter ? 'bg-gray-200' : 'bg-transparent'}`}
              style={{ flex: 0 }}
            >
              <span className="font-medium whitespace-nowrap">
                {t('checked_in')}
              </span>
              {' '}
              <span className="text-xs text-gray-500">
                {formatNumber(checkInCount)}
              </span>
            </Link>
          </div>
          <div className="relative">
            <span className="absolute top-0 bottom-0 text-gray-400 left-2">
              <span className="flex items-center h-full pb-0.5">
                <Icon>
                  <Search />
                </Icon>
              </span>
            </span>
            <Input
              type="text"
              onChange={handleSearchChange} className="pl-8 pr-9"
              ref={searchRef}
              defaultValue={params.search || ''}
              aria-label={t('search')}
            />
            {search && (
              <button
                onClick={() => resetSearch()}
                className="absolute top-0 bottom-0 right-0 px-2 text-gray-400"
              >
                <Icon>
                  <XCircle />
                </Icon>
              </button>
            )}
          </div>
        </div>
        {codes && (
          <>
            <hr className="mb-2" />
            {codes.map((code) => {
              const { checkedIn } = validateCode(code, false);

              return (
                <Link
                  to={`/${event.id}/scanners/${scannerId}/codes/${code.id}`}
                  className="flex items-center px-4 py-1 sm:px-0"
                  key={code.id}
                >
                  <div className="mr-2">
                    <ScanStatusIcon checkedIn={checkedIn} valid />
                  </div>
                  <div style={{ lineHeight: 1.1 }}>
                    <div>
                      <strong>
                        {code.title}
                      </strong>
                      {' '}
                      {code.start_number && (
                         <span className="inline-block px-1 py-0.25 text-xs text-gray-800 bg-gray-200 leading-sm font-medium">
                          {code.start_number}
                        </span>
                      )}
                    </div>
                    <div className="space-x-2">
                      <small className="text-gray-500">{checkedIn ? t('checked_in') : t('not_checked_in')}</small>
                      <code className="text-xs text-gray-400">{code.value}</code>
                    </div>
                  </div>
                </Link>
              );
            })}
            {!isAtEnd && (
              <div className="p-4 sm:px-0">
                <LinkButton to={nextPageLink} background="gray">
                  {t('show_more')}
                </LinkButton>
              </div>
            )}
          </>
        )}
        {!!scannerId && codes.length ===0 && (
          <div className="py-4 text-center text-gray-400">
            {t('no_codes_found')}
          </div>
        )}
      </Container>
    </div>
  );
}

export default CodesPage;
