import { ReactNode, useContext } from 'react';

import { EventContext } from './EventProvider';
import NavBar from './NavBar';
import { StatusBar } from './StatusBar';

interface AppLayoutProps {
  children?: ReactNode;
}

const AppLayout = ({ children }: AppLayoutProps) => {
  const event = useContext(EventContext);

  return (
    <div className="pt-12 pb-6">
      <NavBar event={event} />
      {children}
      <StatusBar />
    </div>
  );
}

export default AppLayout;
