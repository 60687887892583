import { ReactNode } from 'react';

import { inProductionEnvironment } from './helpers';

const { Provider, ErrorBoundary } = require('@rollbar/react');

const config = {
  enabled: inProductionEnvironment(),
  accessToken: '17bd9cccdd554c92834405c2f09eba1c',
  environment: inProductionEnvironment() ? 'production' : undefined,
  captureUncaught: true,
  captureUnhandledRejections: true,
  itemsPerMinute: 1,
  captureIp: 'anonymize',
  checkIgnore: (isUncaught: boolean, args: any, payload: any) => {
    const exception: { class: string, message: string } | undefined = payload?.body?.trace?.exception;

    if (exception) {
      return exception.class === 'NotAllowedError'
        || (exception.class === 'InvalidAccessError' && exception.message === 'Track has ended')
        || (exception.class === 'InvalidStateError' && exception.message === 'Failed to start the audio device')
        || (exception.class === 'OperationError' && exception.message === 'The associated Track is in an invalid state')
        || (exception.class === 'UnknownError' && exception.message === 'setOptions failed')
        || (exception.class === 'UnknownError' && exception.message === 'setPhotoOptions failed');
    }

    return false;
  },
};

export interface RollbarProps {
  children?: ReactNode;
}

const Rollbar = ({ children }: RollbarProps) => {
  return (
    <Provider config={config}>
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
    </Provider>
  );
};

export default Rollbar;
