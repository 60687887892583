const failSafe = (callback: () => any) => {
  try {
    return callback();
  } catch (e) {
    return false;
  }
};

const LocalStorage = {
  getItem: (key: string): string | null => (
    failSafe(() => window.localStorage?.getItem(key)) || null
  ),
  setItem: (key: string, value: string): void => (
    failSafe(() => window.localStorage?.setItem(key, value)) || undefined
  ),
  removeItem: (key: string): void => (
    failSafe(() => window.localStorage?.removeItem(key)) || undefined
  ),
  clear: () => window.localStorage?.clear(),
};


export default LocalStorage;
