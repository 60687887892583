import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useOfflineStatus } from "./OfflineStatusProvider";
import { useScanQueue } from "./ScanQueueProvider";

import './StatusBar.css';
import { useDebouncedEffect } from "./useDebouncedEffect";

export const StatusBar = () => {
  const { t } = useTranslation();
  const { isOffline } = useOfflineStatus();
  const { queue } = useScanQueue();

  const [longWait, setLongWait] = useState(false);

  /**
   * Give the server some time to process the queue, to avoid flashing the status bar on every scan.
   */
  useDebouncedEffect(() => {
    setLongWait((queue || []).length > 0);
  }, 3000, [queue]);

  const showQueue = queue && queue.length > 0 && (longWait || isOffline);

  /**
   * Hide the status bar when no queue is active, or if it's empty and we're online.
   */
  if (!showQueue && !isOffline) {
    return null;
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t">
      <div className="mx-auto" style={{ maxWidth: 600 }}>
        <div className="flex items-center justify-end h-6 px-4">
          <div className="flex items-center justify-end text-xs font-medium text-gray-500" style={{ fontSize: 11 }}>
            {showQueue && (
              <>
                <span className="mr-1 bg-yellow-600 circle"></span>
                {queue.length === 1 && (
                  <>
                    {t('n_scans_queued_one', { scans: queue.length })}
                  </>
                )}
                {queue.length > 1 && (
                  <>
                    {t('n_scans_queued_other', { scans: queue.length })}
                  </>
                )}
              </>
            )}
            {!showQueue && isOffline && (
              <>
                <span className="mr-1 bg-gray-500 circle"></span>
                {t('offline')}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
