import {
  ButtonHTMLAttributes, DetailedHTMLProps, FormEvent, FormHTMLAttributes, ForwardedRef, forwardRef,
  HTMLAttributes, InputHTMLAttributes, RefObject
} from 'react';
import { useTranslation } from 'react-i18next';

import './UI.css'
import { Link, LinkProps } from 'react-router-dom';

type HTMLDivProps = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
type HTMLSpanProps = DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;
type HTMLFormProps = DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>;
type HTMLInputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
type HTMLButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

interface ContainerProps extends HTMLDivProps { }

export const Container = ({ children, className, style, ...props }: ContainerProps) => (
  <div className={`container mx-auto ${className || ''}`} {...props} style={{ maxWidth: 600, ...style }}>
    {children}
  </div>
);

interface FormGridProps extends HTMLDivProps { }

export const FormGrid = ({ children, className, ...props }: FormGridProps) => (
  <div className={`grid gap-4 ${className || ''}`} {...props}>
    {children}
  </div>
);

interface FormProps extends HTMLFormProps {
  ref?: ((instance: HTMLFormElement) => void) | RefObject<HTMLFormElement>;
}

export const Form = forwardRef(({ onSubmit, ...props }: FormProps, ref: ForwardedRef<HTMLFormElement>) => {
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.persist();

    onSubmit?.(event);
  };

  return <form onSubmit={handleSubmit} {...props} />;
});

interface InputProps extends HTMLInputProps {
  ref?: ((instance: HTMLInputElement) => void) | RefObject<HTMLInputElement>;
}

export const Input = forwardRef(({ className, ...props }: InputProps, ref: ForwardedRef<HTMLInputElement>) => (
  <input
    type="text"
    className={`focus:ring-purple-500 focus:border-purple-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${className || ''}`}
    ref={ref}
    {...props}
  />
));

const BUTTON_THEMES = {
  purple: 'bg-purple-600 hover:bg-purple-700 focus:ring-purple-500 text-white',
  gray: 'bg-gray-300 hover:bg-gray-200 focus:ring-gray-200 text-gray-800',
  transparent: '',
};

interface ButtonProps extends HTMLButtonProps {
  background?: keyof typeof BUTTON_THEMES;
  size?: 'sm' | 'md' | 'lg';
  ref?: ((instance: HTMLButtonElement) => void) | RefObject<HTMLButtonElement>;
}

export const Button = ({ className, background = 'purple', size = 'md', ...props }: ButtonProps) => {
  const colorClasses = typeof BUTTON_THEMES[background] !== 'undefined'
    ? BUTTON_THEMES[background] : BUTTON_THEMES.purple;

    const sizeClasses = {
      sm: 'py-1 px-2 text-sm',
      md: 'py-2 px-4 text-sm',
      lg: 'py-4 px-8 text-md',
    }[size];

  return (
    <button
      className={`group relative w-full flex justify-center border border-transparent font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${sizeClasses} ${colorClasses} ${!props.disabled || 'cursor-not-allowed'} ${className || ''}`}
      {...props}
    />
  );
};

interface LinkButtonProps extends LinkProps {
  background?: keyof typeof BUTTON_THEMES;
  size?: 'sm' | 'md' | 'lg';
}

export const LinkButton = ({ className, background = 'purple', size = 'md', ...props }: LinkButtonProps) => {
  const colorClasses = typeof BUTTON_THEMES[background] !== 'undefined'
    ? BUTTON_THEMES[background] : BUTTON_THEMES.purple;

    const sizeClasses = {
      sm: 'py-1 px-2 text-sm',
      md: 'py-2 px-4 text-sm',
      lg: 'py-4 px-8 text-md',
    }[size];

  return (
    <Link
      className={`group relative w-full flex justify-center border border-transparent font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${sizeClasses} ${colorClasses} ${className || ''}`}
      {...props}
    />
  );
};

interface IconProps extends HTMLSpanProps { }

export const Icon = ({  className, ...props }: IconProps) => (
  <span className={`icon ${className || ''}`} {...props} />
);

export const PageLoader = () => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center justify-center min-h-screen text-gray-400">
      {t('loading')}
    </div>
  );
};
