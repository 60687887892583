import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from './UI';
import { Code } from './helpers';
import { useDate } from './useDate';
import useScanSound from './useScanSound';
import { LocalScanInput, LocalScanResult } from './CodesProvider';

export interface CheckInButtonProps {
  code: Code;
  scannerId: string;
  onToggle?: (scan: LocalScanInput) => LocalScanResult | null;
}

const CheckInButton = ({ code, scannerId, onToggle }: CheckInButtonProps) => {
  const { t } = useTranslation();
  const { formatUTC } = useDate();

  const playScanSound = useScanSound();

  const timerRef = useRef<number>();

  useEffect(() => {
    return () => window.clearTimeout(timerRef.current);
  }, [timerRef]);

  const [scanning, setScanning] = useState(false);

  const toggle = () => {
    const input: LocalScanInput = {
      scanner_id: scannerId,
      value: code.value,
      scanned_at: formatUTC(new Date(Date.now())), // Use Date.now to be able to mock the time in tests.
      revoke: !!code.scanned_at,
    };

    // Disable the button for a while so it can't be spammed
    setScanning(true);

    const result = onToggle?.(input);

    if (result) {
      playScanSound(result);
    }

    timerRef.current = window.setTimeout(() => {
      setScanning(false);
    }, 300);
  };

  return (
    <Button
      onClick={() => toggle()}
      disabled={scanning}
      background={'gray'}
      className={scanning ? 'opacity-50' : ''}
    >
      {!code.scanned_at && t('check_in')}
      {code.scanned_at && t('check_out')}
    </Button>
  )
};

export default CheckInButton;
