import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Container, Icon } from './UI';
import { EventContext } from './EventProvider';
import { CornerRightUp } from 'react-feather';
import AppLayout from './AppLayout';

const EventPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const event = useContext(EventContext);

  useEffect(() => {
    if (event.enabled_scanners.length === 1) {
      history.push(`/${event.id}/scanners/${event.enabled_scanners[0].id}`);
    }
  }, [event, history]);

  if (event.enabled_scanners.length === 0) {
    return (
      <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <Container className="pb-8 text-center text-gray-500">
          {t('no_scanners_yet')}
        </Container>
      </div>
    );
  }
  return (
    <AppLayout>
      <div className="pt-16">
        <Container className="py-4 text-right text-gray-500">
          <div className="mr-7 lg:mr-3 ">
            {t('select_scanner_to_continue')}
            <Icon className="ml-2" style={{ marginTop: -10 }}><CornerRightUp /></Icon>
          </div>
        </Container>
      </div>
    </AppLayout>
  );
}

export default EventPage;
