import { useHistory } from 'react-router-dom';
import { createContext, ReactNode } from 'react';
import qs from 'qs';

import { useGetEventByPasswordQuery } from "./global";
import { Event, useSession } from './SessionProvider';

export interface EventProviderProps {
  eventId: string;
  children?: ReactNode;
}

export const eventConfig: { headers: { [key: string]: string; }; } = {
  headers: {},
};

export const EventContext = createContext({} as Event);

const EventProvider = ({ eventId, children }: EventProviderProps) => {
  const history = useHistory();
  const { getEvent, saveEvent, setLastEventId, logout } = useSession();
  const { token: urlToken }: { token?: any; } = qs.parse(history.location.search.substr(1));

  /**
   * The Event can be offline cached in localStorage.
   */
  const event = getEvent(eventId);

  if (event?.token) {
    eventConfig.headers['X-Event-Token'] = event.token;
  }

  useGetEventByPasswordQuery({
    variables: {
      password: urlToken || getEvent(eventId)?.token || '',
    },
    onCompleted: ({ eventByPassword: event }) => {
      if (event?.id === eventId) {
        saveEvent(event);
        setLastEventId(event.id);
      } else {
        // Invalid token
        logout(eventId);
      }
    },
    skip: event?.id === eventId, // Already loaded
  });

  if (!event) {
    return <></>;
  }

  return (
    <EventContext.Provider value={event}>
      {children}
    </EventContext.Provider>
  );
}

export default EventProvider;
