import { useEffect, useMemo, useRef, useState } from 'react';
import useMemoizedValue from './useMemoizedValue';
import QueryString from 'qs';
import { useHistory } from 'react-router-dom';

export interface UseClientPaginateOptions<T> {
  perPage: number;
  searchAttributes: string[];
  filter?: (item: T) => boolean;
  onChange?: (items: T[]) => void;
}

/**
 * Paginate items client-side only.
 *
 * This is useful for paginating items that visually should have controls for navigating front and back, without
 * necessarily needing an API connection.
 */
function useClientPaginate<T>(
  baseItems: T[],
  { perPage = 5, searchAttributes, filter, onChange }: UseClientPaginateOptions<T>,
) {
  const history = useHistory();

  const params: {
    search?: string | null;
    limit?: string | null;
  } = QueryString.parse(history.location.search.substr(1));

  const [search, setSearch] = useState<string>(params.search || '');
  const limit = parseInt(params.limit || '') || perPage;

  const handleSearch = (value: string | null) => {
    setSearch(value || '');

    history.replace(`?${QueryString.stringify({ ...params, search: value || null, limit: null }, { skipNulls: true })}`);
  }

  searchAttributes = useMemoizedValue(searchAttributes);

  const filteredItems = useMemo(() => {
    const normalize = (value: string) => value.toLowerCase().trim();

    const filteredItems = filter ? baseItems.filter(filter) : baseItems;

    const searchTerm = normalize(search);

    if (!searchTerm) {
      return filteredItems;
    }

    const matches: number[] = [];

    // Strict match
    const strictMatches = filteredItems.filter((item, index) => {
      return searchAttributes.find(attribute => {
        const property = (item as any)[attribute] || '';
        if (normalize(property) === searchTerm) {
          matches.push(index);
          return true;
        }
        return false;
      });
    });

    const looseMatches  = filteredItems.filter((item, index) => {
      if (matches.includes(index)) {
        // This item has already been matched.
        return false;
      }

      return searchAttributes.find(attribute => {
        const property = (item as any)[attribute] || '';
        return normalize(property).includes(searchTerm);
      });
    });

    return [
      ...strictMatches,
      ...looseMatches,
    ];
  }, [filter, search, searchAttributes, baseItems]);

  const paginatedItems = useMemo(() => filteredItems.slice(0, limit), [filteredItems, limit]);

  const firstRender = useRef(true);

  // Execute onPageChange after first Render
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    onChange?.(paginatedItems);
  }, [onChange, paginatedItems]);

  const nextPageLink = `?${QueryString.stringify({
    ...params,
    search: search || null,
    limit: limit + perPage
  }, { skipNulls: true })}`;

  const isAtEnd = paginatedItems.length === filteredItems.length;

  return {
    paginatedItems,
    isAtEnd,
    search,
    nextPageLink,
    handleSearch,
  };
}

export default useClientPaginate;
