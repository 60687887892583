import { Check, AlertCircle, X } from 'react-feather';

import { Icon } from './UI';

interface ScanStatusIconProps {
  checkedIn: boolean;
  valid: boolean;
}

const ScanStatusIcon = ({ checkedIn, valid }: ScanStatusIconProps) => {
  return (
    <Icon className={`block rounded-md p-2 transition-colors text-white text-xl ${checkedIn ? (valid ? 'bg-green-500' : 'bg-yellow-500') : 'bg-gray-400'}`}>
      {checkedIn ? (valid ? <Check /> : <AlertCircle />) : <X />}
    </Icon>
  );
};
export default ScanStatusIcon;
