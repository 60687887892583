import './LoginPage.css';

import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Button, Container, Form, FormGrid, Input } from './UI';
import { useGetEventByPasswordLazyQuery } from './global';
import { useSession } from './SessionProvider';

const LoginPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loginError, setLoginError] = useState<boolean>(false);
  const firstRender = useRef(true);

  const { setLastEventId, saveEvent, getLastEventId } = useSession();
  const passwordRef = useRef<HTMLInputElement>(null);

  const redirect = useCallback((eventId: string) => {
    history.push(`/${eventId}`)
  }, [history]);

  const [login, { loading }] = useGetEventByPasswordLazyQuery({
    onCompleted: ({ eventByPassword: event }) => {
      if (event) {
        saveEvent(event);
        setLastEventId(event.id);

        redirect(event.id);
      } else {
        setLoginError(true);
      }
    },
    fetchPolicy: 'no-cache',
  });

  /**
   * On initial load.
   * 1. Try to login the user if a password is in the URL.
   * 2. Redirect a user if eventId is known (already logged in).
   */
  const lastEventId = getLastEventId();

  useEffect(() => {
    if (firstRender.current && lastEventId) {
      redirect(lastEventId);
    }

    firstRender.current = false;
  }, [firstRender, redirect, lastEventId]);

  return (
    <>
      <div className="bg-purple-100" style={{ background: '#cdd0e4' }}>
        <Container style={{ zIndex: 1 }}>
          <div className="intro">
            <img
              src="/intro.svg"
              alt="QR scanner"
              className="absolute top-0 left-0"
            />
          </div>
        </Container>
      </div>

      <div className="px-4 pb-4">
        <Container className="relative px-6 py-6 bg-white rounded-md shadow-lg" style={{ marginTop: -30, zIndex: 2 }}>
          <Form onSubmit={() => login({ variables: { password: passwordRef?.current?.value || '' } })}>
            <FormGrid className="text-center">
              <div>
                <img src="/logo192.png" alt="EventScanner" width="48" className="mx-auto mb-2 rounded-md" style={{ marginTop: -48 }} />
                <h2 className="mb-1 text-purple-800">EventScanner</h2>
                <label htmlFor="password">{t('enter_password_to_continue')}</label>
              </div>
              <Input
                id="password"
                ref={passwordRef}
                disabled={loading}
                className={
                  `text-center
                  ${loginError && 'border-red-500 focus:ring-red-500 focus:border-red-500'}
                  ${loading && 'bg-gray-100'}
                `}
              />
              {loginError && (
                <div className="text-center text-red-700">
                  {t('invalid_password')}
                </div>
              )}

              <Button
                type="submit"
                disabled={loading}
              >
                {t('login')}
              </Button>
            </FormGrid>
          </Form>
        </Container>
      </div>
    </>
  );
}

export default LoginPage;
