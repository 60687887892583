import { useHistory } from 'react-router-dom';

import LocalStorage from './LocalStorage';
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { GetEventByPasswordQuery } from './global';
import { makeCodeIndexedDB } from './CodeIndexedDB';

export interface SessionProviderProps {
  children?: ReactNode;
}

export type Event = NonNullable<GetEventByPasswordQuery['eventByPassword']>;

export interface Session {
  getLastEventId: () => string | null;
  setLastEventId: (eventId: string) => void;
  getEvent: (eventId: string) => Event | null;
  saveEvent: (event: Event) => void;
  logout: (eventId: string) => void;
}

export const SessionContext = createContext({} as Session);

interface Events {
  [eventId: string]: Event;
};

const SessionProvider = ({ children }: SessionProviderProps) => {
  const history = useHistory();

  const getSessionKey = useCallback((eventId: string) => `session:event:${eventId}`, []);

  const getEventFromStorage = useCallback((eventId: string): Event | null => {
    try {
      return JSON.parse(LocalStorage.getItem(getSessionKey(eventId)) as any);
    } catch (error) {
      return null;
    }
  }, [getSessionKey]);

  const eventIdsSessionKey = 'session:eventIds';
  const eventIds = JSON.parse(LocalStorage.getItem(eventIdsSessionKey) || '[]');

  const [events, setEvents] = useState<Events>(
    eventIds.map((eventId: string) => getEventFromStorage(eventId))
      .filter((event: Event) => !!event)
      .reduce((events: Events, event: Event) => ({
        ...events,
        [event.id]: event,
      }), {})
  );

  useEffect(() => {
    LocalStorage.setItem(eventIdsSessionKey, JSON.stringify(Object.keys(events)));
  }, [events]);

  const lastEventIdSessionKey = 'session:lastEventId';
  const getLastEventId = useCallback(() => LocalStorage.getItem(lastEventIdSessionKey), []);
  const setLastEventId = useCallback((eventId: string) => {
    LocalStorage.setItem(lastEventIdSessionKey, eventId);
  }, []);
  const unsetLastEventId = useCallback(() => {
    LocalStorage.removeItem(lastEventIdSessionKey);
  }, []);

  const getEvent = useCallback((eventId: string) => events[eventId] || null, [events]);

  const saveEvent = useCallback((event: Event) => {
    LocalStorage.setItem(getSessionKey(event.id), JSON.stringify(event));
    setEvents((events) => ({
      ...events,
      [event.id]: event,
    }));
  }, [getSessionKey]);

  const forgetEvent = useCallback((eventId: string) => {
    LocalStorage.removeItem(getSessionKey(eventId));
    setEvents((events) => {
      delete events[eventId];

      return events;
    });
  }, [getSessionKey]);

  // 1. Forget the event
  // 2. Forget the last active event
  // 3. Remove all codes from the device
  // 4. Redirect to the login page
  const logout = useCallback((eventId: string) => {
    forgetEvent(eventId);
    unsetLastEventId();

    makeCodeIndexedDB(eventId).then((db) => {
      db.clear(); // Clearing is possible while connections are open, while deleting the database is not.
    });

    history.push('/');
  }, [forgetEvent, unsetLastEventId, history]);

  // Delete old code databases from the device
  indexedDB.databases().then((databases) => {
    databases.forEach((database) => {
      if (database.name) {
        const eventId = (database.name.match(/codes-(.+)/) || [])[1];

        if (eventId) {
          const event = getEvent(eventId);

          let expired = !event;

          if (event) {
            // Check if the token has been issued more than 12 hours ago
            const issuedAt = JSON.parse(atob(event.token.split('.')[1])).iat as number;
            const expiresAt = issuedAt + (60 * 60 * 12);
            expired = (Date.now() / 1000) > expiresAt;
          }

          if (expired) {
            indexedDB.deleteDatabase(database.name);

            forgetEvent(eventId);
          }
        }
      }
    })
  });

  return (
    <SessionContext.Provider value={{
      getLastEventId,
      setLastEventId,
      getEvent,
      saveEvent,
      logout,
    }}>
      {children}
    </SessionContext.Provider>
  );
}

export const useSession = () => useContext(SessionContext);

export default SessionProvider;
